import React from 'react';
import styled from 'styled-components';
import { Box } from '../Box/Box';
import {PADDING, COLOR} from '../../utils/constants';
import { parseContent } from '../../utils/helpers';
import Section from '@latitude/section';

const TitleRow = styled.div`
  display: flex;
`;

const List = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NumberCircle = styled.div`
  padding: 3px 11.5px;
  background-color: #0046aa;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  height: fit-content;
`;

const ItemTitle = styled.h3`
  margin: 0;
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
`;

const ItemDescription = styled.p`
  margin: 0 0 0 48px;
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
`;


const ApplyEligibility = props => {
  const { title, data, bgColor = COLOR.BLUE, id } = props;

  return (
    <Section minHeight="auto" heading={title} id={id} css={`
      background-color: #f2f2f2;
      padding: 32px 0;
      && h2 {
      color: #000;
      font-family: "Montserrat";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      text-align: start !important;
      margin: 0 0 32px 0;
    }`}>
      <Box padding={PADDING.P32} backgroundColor={"#ffffff"}>
        <List>
          {data.map((item, i) => (
             <Item key={i}>
              <TitleRow>
            <NumberCircle>{i + 1}</NumberCircle>
            <ItemTitle>{item.title}</ItemTitle>
            </TitleRow>
            <ItemDescription css= {
              `&& ul {
                list-style-type: disc;
                padding-inline-start: 32px;
              }`
            }>{parseContent(item.description)}</ItemDescription>
          </Item>
          ))}
        </List>
      </Box>
    </Section>
  );
};

export default ApplyEligibility;
